<template>
    <CommonLayout :tabs="tabs"  title="Numbers">
        <LoadingScreen />
        <slot></slot>
    </CommonLayout>
</template>

<script setup lang="ts">
const tabs = [
    {
        title: 'My Numbers',
        link: '/numbers',
    },
    {
        title: 'Registrations',
        link: '/number-registrations',
    }
]

useHead({
  titleTemplate: "%s | Beesphone",
});
</script>